export const SIGN_IN = 'SIGN_IN';
export const LOG_OUT = 'LOG_OUT';
export const CURRENT_USER = 'CURRENT_USER';
export const GET_SURVEY = 'GET_SURVEY';
export const GET_SNIPPET = 'GET_SNIPPET';
export const SURVEY_LIST = 'SURVEY_LIST';
export const SURVEY_DELETE = 'SURVEY_DELETE';
export const FEEDBACK_LIST = 'FEEDBACK_LIST';
export const DASHBOARD_DETAILS = 'DASHBOARD_DETAILS';
export const REPORTS = 'REPORTS';
export const BRAND_NAME = 'BRAND_NAME';
